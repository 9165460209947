<template>
  <div class="project-overview">
    <div class="project-env-list__controls">
      <v-text-field
        v-model="search"
        hide-details
        class="search-field"
        :placeholder="$t('searchEnvsAndComponents')">
        <template #prepend-inner>
          <v-icon
            class="search-field__icon"
            size="20px">
            search
          </v-icon>
        </template>
      </v-text-field>
      <div class="d-flex space-x-2">
        <CyButtonToggle
          v-model="componentListDisplayMode"
          :items="$static.displayModes"
          active-theme="secondary"/>
        <CyMenu
          v-model="addMenuIsOpen"
          offset-y
          left
          content-class="add-menu"
          :close-on-content-click="false"
          @input="(open) => { if (open) { pickingEnv = false } }">
          <template #activator="{ on, attrs }">
            <CyButton
              elevation="0"
              v-bind="attrs"
              v-on="on">
              {{ $t('addNew') }}
              <v-icon
                right
                class="ml-1 mr-n1">
                mdi-menu-down
              </v-icon>
            </CyButton>
          </template>
          <v-list
            v-if="pickingEnv"
            class="env-list">
            <v-list-item
              class="env-list__header"
              @click="pickingEnv = false">
              <v-icon
                color="primary"
                class="mr-2">
                chevron_left
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>
                    {{ $t('environment.component.add') }}
                  </h3>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('selectEnvToAddComponent') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-0"/>
            <v-text-field
              v-model="addMenuSearch"
              autofocus
              hide-details
              height="36px"
              class="search-field"
              :placeholder="$t('environment.find')">
              <template #prepend-inner>
                <v-icon
                  class="search-field__icon"
                  size="20px">
                  search
                </v-icon>
              </template>
            </v-text-field>
            <v-list-item
              v-for="env in addComponentFilteredEnvs"
              :key="env.canonical"
              class="env-list-item"
              role="listitem"
              :aria-label="env.canonical"
              @click="redirectToNewEnvComponent(env.canonical)">
              <CyAvatar
                :item="{ canonical: env.canonical, icon: 'mdi-layers-outline', color: env.color }"
                class="env-list-item__avatar"
                sm/>
              <v-list-item-content class="env-list-item__name">
                {{ env.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else
            class="add-menu-list">
            <v-list-item
              class="add-menu-list__item"
              @click="onAddMenuEnvClick">
              <v-list-item-title>
                <div class="icon-wrapper">
                  <v-icon
                    size="20"
                    color="primary">
                    mdi-layers-outline
                  </v-icon>
                </div>
                {{ $t('environment.add') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              class="add-menu-list__item"
              @click="pickingEnv = true">
              <v-list-item-title>
                <div class="icon-wrapper">
                  <v-icon
                    size="16"
                    color="primary"
                    class="rotate-45"
                    small>
                    mdi-grid-large
                  </v-icon>
                </div>
                {{ $t('environment.component.add') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </CyMenu>
      </div>
    </div>
    <div
      v-if="noResults"
      class="project-env-list--empty">
      {{ $t('noMatchingEnvsOrComponents') }}
    </div>
    <div
      :class="['project-env-list', {
        'project-env-list--card-mode': componentListDisplayMode === 'card',
        'project-env-list--list-mode': componentListDisplayMode === 'list',
      }]">
      <div
        v-show="envsMatchingSearch.includes(env.canonical) || !_.isEmpty(filteredEnvComponents[env.canonical])"
        v-for="env in sortedEnvs"
        :key="env.canonical"
        class="project-env-list__item">
        <div
          role="tab"
          :class="['project-env-list__header', {
            'project-env-list__header--active': openActionsEnv === env.canonical,
            'project-env-list__header--empty': _.isEmpty(filteredEnvComponents[env.canonical]),
          }]"
          @click="redirectToEnvOverview(env.canonical)">
          <div class="project-env-list__header-items">
            <v-icon
              v-if="!_.isEmpty(filteredEnvComponents[env.canonical])"
              :class="['expand-chevron', {
                'expand-chevron--rotate': !collapsedEnvs.includes(env.canonical),
              }]"
              data-cy="expand-toggle-btn"
              @click.stop="toggleEnv(env.canonical)">
              mdi-chevron-right
            </v-icon>
            <div
              class="env-link"
              role="button">
              <CyAvatar
                :item="{ canonical: env.canonical, icon: 'mdi-layers-outline', color: env.color }"
                class="env-icon"
                sm/>
              <h3 class="env-name">
                {{ env.name }}
              </h3>
              <div class="env-component-count">
                {{ envComponents(env.canonical).length }}
              </div>
            </div>
            <div class="display-flex flex-grow-1"/>
            <div
              v-if="componentListDisplayMode === 'list' && collapsedEnvs.includes(env.canonical)"
              class="env-pipelines">
              <CyPipelineStatus
                v-for="pipeline in getEnvPipelines(env.canonical)"
                :key="pipeline.id"
                :status="pipeline.status"
                icon-only/>
            </div>
          </div>
          <div class="project-env-list__header-actions">
            <v-icon
              color="primary"
              @click.stop="redirectToNewEnvComponent(env.canonical)">
              add_circle_outline
            </v-icon>
            <CyMenu
              v-if="!_.isEmpty(getEnvActions(env))"
              offset-y
              left
              sm
              min-width="300"
              @input="onEnvMenuToggle(env, $event)">
              <v-list>
                <template v-for="({ action, label, icon, color, divider }, index) in getEnvActions(env)">
                  <v-divider
                    v-if="divider"
                    :key="`menu-divider-${index}`"/>
                  <v-list-item
                    v-else
                    :key="`menu-item-${index}`"
                    v-hover-siblings
                    @click.stop="action">
                    <v-list-item-content>
                      <v-list-item-title
                        class="d-flex align-center"
                        :style="{ color: color }">
                        <v-icon
                          class="mr-2"
                          :color="color || 'primary'"
                          size="24">
                          {{ icon }}
                        </v-icon>
                        <span :class="{ 'error--text': color === 'error' }">
                          {{ label }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </CyMenu>
          </div>
        </div>
        <transition
          name="expand-collapse"
          @enter="enter"
          @leave="leave">
          <div
            v-show="!collapsedEnvs.includes(env.canonical)"
            :ref="`expand-transition-${env.canonical}`"
            class="project-env-list__content">
            <CyEnvComponentList
              :env-canonical="env.canonical"
              :project-canonical="projectCanonical"
              :env-components="filteredEnvComponents[env.canonical]"
              :display-mode="componentListDisplayMode"
              nested
              @delete="(envComponent) => { envComponentToDelete = envComponent }"/>
          </div>
        </transition>
      </div>
    </div>
    <CyFormsEnvironmentDelete
      v-if="environmentToDelete"
      :environment="environmentToDelete"
      :project-canonical="projectCanonical"
      @cancel="onCancelButtonClick"
      @success="onDeleteSuccess"/>
    <CyModal
      v-if="envComponentToDelete"
      :header-title="$t('environment.component.delete')"
      :loading="deletingEnvComponent"
      :action-btn-func="onDeleteComponent"
      :cancel-btn-func="onCancelButtonClick"
      :action-btn-text="$t('forms.btnDelete')"
      keep-open-on-action-click
      modal-type="delete"
      small>
      <div class="pb-5">
        <p class="font-weight-bold">
          {{ $t('environment.component.deleteInfo') }}
        </p>
        <div class="d-flex align-center">
          <p class="ma-0 mr-2">
            {{ $t('environment.component.deleteConfirm') }}
          </p>
          <span class="d-flex align-center">
            <CyStackAvatar
              :stack="envComponentToDelete.stack"
              :size="24"/>
            <div class="font-size-base">{{ envComponentToDelete.name }}</div>
          </span>
        </div>
      </div>
    </CyModal>
    <v-slide-x-reverse-transition>
      <portal
        v-if="showEnvAddModal"
        to="side-panel">
        <CyEnvironmentAddModal
          @close="$toggle.showEnvAddModal"/>
      </portal>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'
import CyEnvComponentList from '@/components/CyEnvComponentList.vue'
import CyEnvironmentAddModal from '@/components/CyEnvironmentAddModal.vue'
import CyFormsEnvironmentDelete from '@/components/CyFormsEnvironmentDelete.vue'
import CyPipelineStatus from '@/components/CyPipelineStatus.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectOverview',
  components: {
    CyButtonToggle,
    CyEnvComponentList,
    CyFormsEnvironmentDelete,
    CyPipelineStatus,
    CyEnvironmentAddModal,
    CyStackAvatar,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('Environments'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
  },
  data: ({ projectCanonical }) => ({
    componentListDisplayMode: localStorage.getItem(`componentListDisplayMode:${projectCanonical}`) || 'card',
    collapsedEnvs: [],
    environmentToDelete: null,
    envComponentToDelete: null,
    deletingEnvComponent: false,
    openActionsEnv: null,
    search: '',
    showEnvAddModal: false,
    pickingEnv: false,
    addMenuSearch: '',
    addMenuIsOpen: false,
  }),
  computed: {
    ...mapGetters('organization/project', [
      'envs',
      'envComponents',
      'envComponentPipeline',
    ]),
    $static () {
      return {
        displayModes: [
          {
            key: 'card',
            value: 'card',
            icon: 'grid_view',
          },
          {
            key: 'list',
            value: 'list',
            icon: 'view_headline',
          },
        ],
      }
    },
    filteredEnvComponents () {
      return this.envs.reduce((acc, env) => {
        return {
          ...acc,
          [env.canonical]: this.getFilteredEnvComponents(env.canonical),
        }
      }, {})
    },
    envsMatchingSearch () {
      if (!this.search) return _.map(this.envs, 'canonical')
      return this.envs
        .filter(({ canonical }) => {
          return canonical.toLowerCase().includes(this.search.toLowerCase())
        })
        .map(({ canonical }) => canonical)
    },
    noResults () {
      return _.every(this.filteredEnvComponents, _.isEmpty) && _.isEmpty(this.envsMatchingSearch)
    },
    addComponentFilteredEnvs () {
      if (!this.addMenuSearch) return this.envs
      return this.envs.filter(({ canonical }) => {
        return canonical.toLowerCase().includes(this.addMenuSearch.toLowerCase())
      })
    },
    sortedEnvs () {
      return _.orderBy(
        this.envs,
        [(env) => _.isEmpty(this.envComponents(env.canonical))],
        ['asc'],
      )
    },
  },
  watch: {
    async componentListDisplayMode (newMode) {
      localStorage.setItem(`componentListDisplayMode:${this.projectCanonical}`, newMode)
      await this.$nextTick()
      this.setDefaultCollapsedStatus()
    },
    envs: {
      handler () {
        this.setDefaultCollapsedStatus()
      },
      immediate: true,
    },
  },
  created () {
    this.GET_PROJECT_PIPELINES(this.projectCanonical)
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
      'DELETE_ENV_COMPONENT',
      'GET_PROJECT_ENVS',
    ]),
    getFilteredEnvComponents (envCanonical) {
      if (this.envsMatchingSearch.includes(envCanonical)) return this.envComponents(envCanonical)
      return this.envComponents(envCanonical).filter((component) => {
        return component.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    getEnvPipelines (envCanonical) {
      return this.envComponents(envCanonical).map((component) => {
        return this.envComponentPipeline(envCanonical, component.canonical)
      }).filter(Boolean)
    },
    setDefaultCollapsedStatus () {
      this.componentListDisplayMode === 'list' ? this.collapseAllEnvs() : this.expandAllEnvs()
    },
    collapseAllEnvs () {
      this.collapsedEnvs = _.map(this.envs, 'canonical')
      this.$nextTick(() => {
        _.forEach(this.envs, (env) => {
          const ref = this.$refs[`expand-transition-${env.canonical}`][0]
          this.leave(ref)
        })
      })
    },
    expandAllEnvs () {
      this.collapsedEnvs = []
      this.$nextTick(() => {
        _.forEach(this.envs, (env) => {
          const ref = this.$refs[`expand-transition-${env.canonical}`][0]
          this.enter(ref)
        })
      })
    },
    toggleEnv (envCanonical) {
      this.collapsedEnvs = _.xor(this.collapsedEnvs, [envCanonical])
    },
    redirectToEnvOverview (envCanonical) {
      this.$router.push({
        name: 'environmentOverview',
        params: { envCanonical },
      })
    },
    redirectToNewEnvComponent (envCanonical) {
      this.$router.push({
        name: 'newEnvComponent',
        params: { envCanonical },
      })
    },
    async onDeleteComponent () {
      this.deletingEnvComponent = true
      await this.DELETE_ENV_COMPONENT({
        envCanonical: this.envComponentToDelete.envCanonical,
        componentCanonical: this.envComponentToDelete.canonical,
      })
      await this.GET_PROJECT_ENVS()
      this.envComponentToDelete = null
      this.deletingEnvComponent = false
    },
    onCancelButtonClick () {
      this.environmentToDelete = null
      this.envComponentToDelete = null
      this.deletingEnvComponent = false
    },
    onDeleteSuccess () {
      if (_.isEmpty(this.envs)) return this.$router.push({ name: 'projectEmpty' })
      this.environmentToDelete = null
    },
    onEnvMenuToggle (env, open) {
      if (this.openActionsEnv && this.openActionsEnv !== env.canonical && !open) return
      this.openActionsEnv = open ? env.canonical : null
    },
    enter (el) {
      el.style.maxHeight = `${el.scrollHeight + 1}px`
    },
    leave (el) {
      el.style.maxHeight = `${el.scrollHeight}px`
      requestAnimationFrame(() => {
        el.style.maxHeight = '0'
      })
    },
    getEnvActions (env) {
      const { canDisplay } = this.$cycloid.permissions
      return [
        {
          key: 'settings',
          icon: 'mdi-cog',
          label: this.$t('environment.settings'),
          action: () => {
            this.$router.push({ name: 'projectConfigurationEnvironment', params: { envCanonical: env.canonical } })
          },
          permissionKey: 'UpdateProject',
        },
        {
          divider: true,
          permissionKey: 'UpdateProject',
        },
        {
          key: 'delete',
          icon: 'delete',
          label: `${this.$t('environment.delete')}...`,
          action: () => { this.environmentToDelete = env },
          color: 'error',
          permissionKey: 'UpdateProject',
        },
      ].filter(({ permissionKey }) => canDisplay(permissionKey, this.projectCanonical))
    },
    onAddMenuEnvClick () {
      this.showEnvAddModal = true
      this.addMenuIsOpen = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectOverview',
        addNew: 'Add new...',
        noMatchingEnvsOrComponents: 'None of the environments or components in this project matched your search',
        searchEnvsAndComponents: 'Search environments and components',
        selectEnvToAddComponent: 'Select the environment to add a component',
      },
      es: {
        title: '@:routes.projectOverview',
        addNew: 'Añadir nuevo...',
        noMatchingEnvsOrComponents: 'Ninguno de los entornos o componentes de este proyecto coincide con su búsqueda',
        searchEnvsAndComponents: 'Buscar entornos y componentes',
        selectEnvToAddComponent: 'Seleccione el entorno para agregar un componente',
      },
      fr: {
        title: '@:routes.projectOverview',
        addNew: 'Ajouter un nouveau...',
        noMatchingEnvsOrComponents: 'Aucun des environnements ou composants de ce projet ne correspond à votre recherche',
        searchEnvsAndComponents: 'Rechercher des environnements et composants',
        selectEnvToAddComponent: `Sélectionnez l'environnement pour ajouter un composant`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-env-list {
  margin-top: -4px;
  padding-bottom: 48px;

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .search-field {
      max-width: 320px;
      height: 32px;
      margin-top: 0;
      padding-top: 0;
      border: 1px solid cy-get-color('primary', 'light-4');
      border-radius: 4px;
      background-color: cy-get-color('white');

      ::v-deep .v-input {
        &__slot {
          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }

      ::v-deep input::placeholder {
        color: cy-get-color('primary', 'light-3');
        font-size: $font-size-default;
      }

      ::v-deep input {
        padding: 8px 0 10px;
        color: cy-get-color('primary', 'light-2');
      }

      &__icon {
        margin-top: 2px;
        margin-left: 8px;
        color: cy-get-color('primary', 'light-3');
      }
    }

    ::v-deep  .v-btn.v-btn.cy-btn {
      height: 32px;
    }
  }

  &__item {
    margin-bottom: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
    padding-left: 8px;
    border-radius: 8px;
    cursor: pointer;

    &-items {
      display: flex;
      align-items: center;
      padding: 4px 0;

      .expand-chevron {
        transition: transform 0.5s ease;
        color: cy-get-color('primary', 'light-3');
        cursor: pointer;

        &--rotate {
          transform: rotate(90deg);
        }
      }

      .env {
        &-link {
          display: flex;
          align-items: center;
          margin-left: 12px;
          padding: 4px;
          padding-right: 8px;
          border-radius: 4px;
        }

        &-icon {
          margin-right: 8px;
        }

        &-name {
          font-size: $font-size-lg;
        }

        &-component-count {
          margin-left: 8px;
          padding-inline: 4px;
          border-radius: 4px;
          background: cy-get-color('grey', 'light-1');
          color: cy-get-color('primary');
          font-size: $font-size-xs;
          font-weight: $font-weight-bold;
        }

        &-pipelines {
          display: flex;
          width: 150px;
          padding-left: 2px;

          .pipeline-status {
            border-radius: 50%;
            background: cy-get-color('white');

            ::v-deep.v-progress-circular {
              border: 1px solid cy-get-color('white');
            }
          }

          @for $i from 1 through 20 {
            .pipeline-status:nth-child(#{$i}) {
              transform: translateX(calc((#{$i} - 1) * -5px));
            }
          }
        }
      }
    }

    &-actions {
      opacity: 0;
    }

    &--active,
    &:hover {
      background-color: cy-get-color('primary', 'light-5');

      .project-env-list__header-actions {
        opacity: 1;
      }
    }

    &--empty {
      .project-env-list__header-items {
        padding-left: 24px;
      }
    }
  }

  &--list-mode {
    margin-top: 16px;
    padding: 12px;
    border: 1px solid cy-get-color('primary', 'light-4');
    border-radius: 8px;
    background-color: cy-get-color('white');

    .project-env-list__item {
      margin-bottom: 0;
    }

    .project-env-list__header {
      margin: 0;

      &-items {
        width: max(85%, 500px);
        max-width: max(85%, 500px);
        padding: 0 2px 0 4px;

        .env-name {
          font-size: $font-size-default;
          font-weight: $font-weight-default;
        }
      }

      &--empty {
        .project-env-list__header-items {
          padding-left: 28px;
        }
      }
    }
  }

  &--empty {
    display: flex;
    justify-content: center;
    padding: 32px;
    color: cy-get-color('primary', 'light-1');
  }
}

.env-list {
  min-width: 260px;
  max-width: 260px;

  &.v-list {
    padding-bottom: 3px !important;
  }

  &-item {
    min-height: 40px;
    max-height: 40px;
    padding-inline: 16px;

    &__avatar {
      margin-right: 8px;
    }

    &__name {
      color: cy-get-color('primary');
    }

    ::v-deep .v-list-item {
      &__icon {
        margin: 0;
      }
    }

    &--focused {
      background-color: cy-get-color('primary', 'light-5');
    }
  }

  &__header {
    align-items: flex-start;
    padding: 16px;
    cursor: pointer;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__title {
      color: cy-get-color("primary");
      font-size: $font-size-default !important;
    }

    .v-list-item__subtitle {
      color: cy-get-color("primary", "light-2") !important;
      font-size: $font-size-sm;
      white-space: normal;
    }
  }

  .search-field {
    height: 36px;
    margin: 8px;
    padding-top: 0;
    border-radius: 4px;
    background-color: cy-get-color('primary', 'light-5');

    ::v-deep .v-input {
      &__slot {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }

    ::v-deep input::placeholder {
      color: cy-get-color('primary', 'light-3');
      font-size: $font-size-default;
    }

    ::v-deep input {
      color: cy-get-color('primary', 'light-2');
    }

    &__icon {
      margin-top: 4px;
      margin-left: 8px;
      color: cy-get-color('primary', 'light-3');
    }
  }
}

.add-menu {
  &-list {
    min-width: 260px;
    max-width: 260px;

    &__item {
      cursor: pointer;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        width: 24px;
        margin-inline: -2px 4px;
      }

      ::v-deep .v-list-item__title {
        display: flex;
      }
    }
  }
}

.expand-collapse-enter-active,
.expand-collapse-leave-active {
  transition: all 0.4s ease;
}

.expand-collapse-enter-from,
.expand-collapse-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
